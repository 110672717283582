<template>
  <b-card title="Partecipanti">
    <b-card-text>Qui trovi tutti i partecipanti del gratta e vinci.</b-card-text>
      <div
        v-if="!contentloaded"
      >
        <b-spinner
          variant="primary"
          class="spinner-def"
        />
      </div>
      <div v-if="contentloaded" class="custom-search d-flex justify-content-start">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              placeholder="Cerca"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

    <!-- table -->
    <vue-good-table
      v-if="contentloaded"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Elimina</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else-if="props.column.field !== 'date_used'">
          {{ props.formattedRow[props.column.field] }}
        </span>
        <span v-else>
          {{ getDate(props.formattedRow[props.column.field]) }}
        </span>
      </template>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostra
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['15','30','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> per volta </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <br>
  </b-card>
</template>

<script>
import {
  BSpinner, BCard, BCardText, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BSpinner,
    BCardText,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 15,
      contentloaded: false,
      dir: false,
      columns: [
        {
          label: 'Nome e cognome',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Numero di telefono',
          field: 'phone',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.getPart()
  },
  methods: {
    getDate(date) {
      return (date) ? window.moment(date).format('DD/MM/YYYY') : ''
    },
    getPart() {
      this.$http.get('/scratch/users').then(response => {
        this.rows = response.data
        this.contentloaded = true
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Errore nel recupero dei partecipanti.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    },
  },
}
</script>

<style>

</style>
